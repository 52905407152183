<template>
    <div class="col-md-12" style="padding-top: 60px;padding: 0px;">
        <div class="scanCamera">

            <div v-if="explanationMessage != ''"  ref="explanationBox" class="explanation-box">
                <span>{{explanationMessage}}</span>
            </div>

            <div class="products fuel-scan-prducts-group" ref="products">
                <div v-for="product in products" :key="product.id">
                    <ProductBloc :scannedProduct="product"></ProductBloc>
                </div>
                <button v-if="!scanCoupon" class="btn valid-button" @click="$router.push($parent.base + '/coupons')">{{$t("useLoyaltyCoupons")}}</button>
                <button v-else class="btn valid-button" @click="$router.push($parent.base +'/scan?showBasket=true')">{{$t("buyOtherProduct")}}</button>
            </div>

            <qrcode-reader id="scanner" ref="scanner" class="cameraCustom" @decode="onDecode" v-if="scanType=='QRCODE' && !unmount"></qrcode-reader>
            <video id="video" ref="barcode" class="cameraCustom" :hidden="!(scanType=='BARCODE')"></video>

            <Alert v-if="alertContent!=''" :alertContent="alertContent" :alertVariant="'danger'"></Alert>
        </div>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import Alert from "@/components/Common/Alert";
    import ProductBloc from "@/components/Common/ProductBloc";
    var Search = require("@/assets/js/SearchProduct");
    var Barcode = require("@/assets/js/Barcode");
    var App = require("@/assets/js/App");
    var MQTT = require("@/assets/js/Fuel/MqttService");
    var Stats = require("@/assets/js/Stats");
    var Basket = require("@/assets/js/Fuel/Basket");
    var Customer = require("@/assets/js/Customer");

    export default {
        name: "Scan",
        components: {ProductBloc, Alert},
        data() {
            return {
                ean: '',
                scanned_products: [],
                products: [],
                timers: {
                    productsTimer: null,
                    scannedProductsTimer: null,
                    errorTimer: null
                },
                scanType: "QRCODE",
                scanCoupon: this.$route.query.scanCoupon,
                alertContent: "",
                explanationMessage: this.$t('scanExplainScanProduct'),
                unmount : false
            }
        },
        mounted() {
            var showQRScanner = false;
            var needQRCodeScanner = false;
            var needBarCodeScanner = false;

            if(isNotNull(this.scanCoupon) && this.scanCoupon == "true"){
                this.explanationMessage = this.$t('messageScanExplainCoupon');
                needQRCodeScanner = true;
            }else{
                needBarCodeScanner = true;
                this.explanationMessage = this.$t('scanExplainScanProduct')
            }

            var scanType = localStorage.getItem("DKC_scanType");
            if ((needBarCodeScanner && !needQRCodeScanner) || (!showQRScanner && isNotNull(scanType) && scanType == "BARCODE")) {
                this.scanType = "BARCODE";
                Barcode.start(this.onDecode);
                this.$refs.barcode.style.height = window.innerHeight + "px";
                this.$refs.barcode.style.width = window.innerWidth + "px";
            }else{
                this.scanType = "QRCODE";
                this.$refs.scanner.$el.style.height = window.innerHeight + "px";
                document.getElementsByClassName("qrcode-reader__camera")[0].style.height = window.innerHeight + "px";
                document.getElementsByClassName("qrcode-reader__inner-wrapper")[0].style.height = window.innerHeight + "px";
            }

        },
        methods: {
            onDecode(decodedString) {
                if(this.$refs.beep){
                    this.$refs.beep.play();
                }
                if(isNotNull(this.needParameters)){
                    this.onParametersScanned(decodedString);
                }else if(isNotNull(this.scanCoupon)){
                    this.onCouponScanned(decodedString);
                }else{
                    this.onProductScanned(decodedString);
                }
            },
            onParametersScanned(decodedString){
                var controlKey = "/p=";
                if(decodedString.includes(controlKey)){
                    var pathname = decodedString.substring(window.location.origin.length);
                    App.checkParameters(pathname, this);
                    this.$parent.setBase();
                    localStorage.setItem("DKC_needPublishPoster", "true");
                    window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + '/');
                }else{
                    this.alertContent = this.$t('scanErrorParameters');
                }
            },
            onProductScanned(decodedString){
                if(this.$refs.products){
                    this.$refs.products.classList.remove("shake");
                }
                if(this.$refs.explanationBox){
                    this.$refs.explanationBox.classList.remove("shake");
                    this.$refs.explanationBox.classList.remove("error-box");
                }
                               
                let self = this;
                let tmpList = [];
                if (decodedString.codeResult) {
                    this.ean = decodedString.codeResult.code;
                } else {
                    this.ean = decodedString.substring(decodedString.lastIndexOf('=') + 1);
                }
                if (this.scanned_products.indexOf(this.ean) == -1) {
                    this.scanned_products.push(this.ean);
                    Search.getProductByEan(this.ean.split("_")[0], function(product) {
                        if (!product.error) {
                            clearTimeout(self.timers.productsTimer);
                            self.$refs.products.classList.add("shake");

                            tmpList.push(product);
                            product.ean = self.ean;
                            self.products = tmpList;
                            self.timers.productsTimer = setTimeout(function() {
                                self.products = [];
                            }, 5000);
                            
                            Basket.addProductToLocalBasket(product);
                            self.$parent.basket = Basket.getBasket();
                            let messageMQTT = {
                                ProductCode : product.descriptionFr,
                                Type : "BUY"
                            }
                            MQTT.publishMessage("PRODUCT_SCANNED", JSON.stringify(messageMQTT));

                            if(isNotNull(product.productCrossSell)){
                                self.$parent.productCrossSell = product.productCrossSell;
                            }

                            self.$refs.explanationBox.classList.add("shake")
                            self.explanationMessage = self.$t('scanExplainAddedProduct');

                            Stats.setStats({nbProductsScanned: 1});
                        }else{
                            self.$refs.explanationBox.classList.add("shake")
                            self.$refs.explanationBox.classList.add("error-box");
                            self.explanationMessage = self.$t('scanExplainScanError');
                        }
                        setTimeout(()=>{
                            self.$refs.explanationBox.classList.remove("error-box");
                            self.explanationMessage = self.$t('scanExplainScanProduct');
                        }, 10000)
                    });
                }
            },
            onCouponScanned(decodedString){
                var valinaKey = "demokit_coffe";
                this.$refs.explanationBox.classList.remove("error-box");
                this.explanationMessage = this.$t('messageScanExplainCoupon');

                let localBasket = JSON.parse(localStorage.getItem("DKC_fuel_basket"));
                if(decodedString.includes(valinaKey)){
                    this.$parent.loader.showLoader = true;
                    this.$parent.loader.content = this.$t("loadingText");
                    let messageMQTT = {
                        ProductCode : "coffe",
                        Type : "LOYALTY"
                    }
                    MQTT.publishMessage("PRODUCT_SCANNED", JSON.stringify(messageMQTT));
                    let loyaltyPoints = parseFloat(localStorage.getItem("DKC_fuel_loyalty"));
                    loyaltyPoints -= 12;
                    Customer.updateCustomerLoyaltyPoints(loyaltyPoints);
                    MQTT.publishMessage('LOYALTY_UPDATE', JSON.stringify({LoyaltyPoints : loyaltyPoints}));
                    localStorage.setItem("DKC_fuel_loyalty", loyaltyPoints)
                    setTimeout(()=>{
                        if(localBasket.products.length>0){
                            this.$router.push(localStorage.getItem("DKC_base") + "/basket");
                        }else{
                            this.$router.push(localStorage.getItem("DKC_base") + "/ticket?fromCoupon=true");
                        }
                    }, 3000)
                    
                }else{
                    this.alertContent = this.$t('scanErrorPayment');
                }
            },
            onSubmit(evt) {
                evt.preventDefault();
            },
            existInBasket(ean) {
                var exist = false;
                this.$parent.basket.lockedProducts.forEach(function(lockedProduct) {
                    if (lockedProduct == ean) {
                        exist = true;
                    }
                })
                return exist;
            }
        },
        beforeRouteLeave(to, from, next) {
            next();
        },
        watch: {
            '$route': function() {
                this.unmount =true;
            },
            scanned_products: function() {
                var self = this;
                clearTimeout(this.timers.scannedProductsTimer);
                this.timers.scannedProductsTimer = setTimeout(function() {
                    self.scanned_products.shift();
                }, 2000)
            }

        }
    }
</script>

<style>
    .fuel-scan-prducts-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .fuel-scan-prducts-group .valid-button{
        width: fit-content;
    }
</style>